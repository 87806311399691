import { getAutocompleteApi, getAutocompleteStoreApi } from './google-api';

export const getCityByZipCode = async (zipCode: number, country: string) => {
    const service = getAutocompleteApi();

    if (service) {
        const t = await service.getPlacePredictions({
            input: `${String(zipCode)}`,
            componentRestrictions: {
                country: country,
            },
            types: ['(regions)'],
        });
        return t?.predictions?.[0]?.terms?.[1]?.value;
    }
};

export const getCoordinatesByZipOrCity = async (zipCodeOrCity: string, country: string) => {
    const service = getAutocompleteStoreApi();

    if (service) {
        const t = await service.geocode({
            address: `${String(zipCodeOrCity)}`,
            componentRestrictions: {
                country: country,
            },
        });

        return {
            lat: t.results[0].geometry.location.lat(),
            lng: t.results[0].geometry.location.lng(),
        };
    }
};
