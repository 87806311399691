import React, { FC } from 'react';
import { InputCheckmark } from '../../controllable-elements/input-element/styled';
import PhoneElement, {
    PhoneElementProps,
} from '../../controllable-elements/phone-element/phone-element';
import { useController, useFormContext } from 'react-hook-form';

export type PhoneProps = Omit<PhoneElementProps, 'isTouched' | 'appendWhenValid'> & {
    appendWhenValid?: boolean;
};

export const Phone: FC<PhoneProps> = ({
    name,
    countryCodeName,
    messages,
    appendWhenValid = true,
    required,
    ...rest
}) => {
    const { control, setValue, trigger } = useFormContext();

    // Phone Number
    const {
        field: { onBlur, onChange: onPhoneChange, value: phoneValue },
        fieldState: { invalid, error, isDirty },
    } = useController({
        name: name ?? '',
        control,
    });

    // Country Code
    const {
        field: { onChange: onCountryCodeChange, value: countryCodeValue },
    } = useController({ name: countryCodeName ?? '', control });

    return (
        <PhoneElement
            {...rest}
            name={name}
            countryCodeName={countryCodeName}
            messages={{ ...messages, error: error?.message }}
            {...(appendWhenValid && { appendWhenValid: <InputCheckmark /> })}
            value={phoneValue}
            countryCodeValue={countryCodeValue}
            isDirty={isDirty}
            isValid={!invalid}
            required={required}
            onBlur={onBlur}
            onChange={onPhoneChange}
            onCountryCodeChange={(countryCode, isOperable) => {
                onCountryCodeChange(countryCode);

                if (name) {
                    // Empty the phone number field when an inoperable code is selected.
                    if (!isOperable) setValue(name, '');

                    // This will re-trigger the validation of the phone number field.
                    trigger(name);
                }
            }}
        />
    );
};
