import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig() || {};

export const googleApiKey = publicRuntimeConfig?.NEXT_PUBLIC_GOOGLE_MAPS_API;

let autocomplete: google.maps.places.AutocompleteService | null = null;

let autocompleteStore: google.maps.Geocoder | null = null;

export const getAutocompleteApi = (): google.maps.places.AutocompleteService | undefined => {
    if (global.google) {
        if (!autocomplete) {
            autocomplete = new google.maps.places.AutocompleteService();
        }

        return autocomplete;
    }
};

export const getAutocompleteStoreApi = (): google.maps.Geocoder | undefined => {
    if (global.google) {
        if (!autocompleteStore) {
            autocompleteStore = new google.maps.Geocoder();
        }

        return autocompleteStore;
    }
};
