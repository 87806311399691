export const isName = /^[a-zA-ZæøåÆØÅ,. -]+$/;
export const isAddress1 = /^[a-zA-ZæøåÆØÅ\- .,]+ [\d]{1,}[a-zA-ZæøåÆØÅ0-9\- .,]*$/;
export const isAddress2 = /^[a-zA-ZæøåÆØÅ0-9\- .,]+$/;
export const isZipCode = /^[0-9]{4}$/;
export const isPhone = /^[0-9]{8,10}$/;
/**
 * REGEX: Only allow 8 non-repeating numbers. 00000000, 11111111, etc. are invalid.
 * Used basic RegExp to easily add invalid mobile number patterns.
 */
export const isPhoneWithoutCountryCode =
    /^(?!(00000000|11111111|22222222|33333333|44444444|55555555|66666666|77777777|88888888|99999999|12345678)$)(\d{8})/;
export const isWord = /\w+/;
export const isEmail = /^[\wæøåÆØÅ\.\-]+@[\wæøåÆØÅ\.\-]+\.[a-zA-Z]{2,}$/;
export const isGender = /^[1|2|3]$/;
