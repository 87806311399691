import { useJsApiLoader } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/src/utils/make-load-script-url';
import { useMemo } from 'react';
import { googleApiKey } from '~/lib/google-api';

interface Options {
    id?: string;
    apiKey?: string;
    libraries?: Libraries;
}

const useGoogleMapsApi = (options?: Options) => {
    // Merge default settings and passed options.

    const settings = useMemo(
        () =>
            Object.assign(
                options ?? {},
                {
                    id: 'google-map-script',
                    googleMapsApiKey: `${googleApiKey}&loading=async`,
                    libraries: ['places'],
                },
                {},
            ),
        [options],
    );

    const loader = useJsApiLoader(settings);

    return loader;
};

export default useGoogleMapsApi;
