import {
    ChangeEventHandler,
    InputHTMLAttributes,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { ascertainPhoneNumber, regionsToCountries, regionsToCountryCodes } from './utils';
import ReactSelect, { SingleValue } from 'react-select';
import {
    customStyles,
    StyledAdditionalContent,
    StyledContainer,
    StyledInput,
    StyledLabel,
    StyledOption,
    StyledOptionCode,
    StyledOptionLabel,
    StyledPhoneElement,
} from './styled';
import { CountryCodeOption } from './types';
import { StyledInvalidMessage } from '../../invalid-message';
import { StyledHelpText } from '../../help-text';

interface Messages {
    inoperableCountryCode?: string;
    error?: string;
}

const CustomOption: React.FC<CountryCodeOption> = ({
    code,
    region,
    country,
}: CountryCodeOption) => (
    <StyledOption>
        <StyledOptionCode>{`+${code}`}</StyledOptionCode>
        <StyledOptionLabel>{country || region}</StyledOptionLabel>
    </StyledOption>
);

export type PhoneElementProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
    operableCountryCodes?: string | string[];
    countryCodeValue?: string;
    value?: string;
    messages?: Messages;
    label?: string;
    countryCodeName?: string;
    appendWhenValid?: React.ReactNode;
    isDirty?: boolean;
    isValid?: boolean;
    maxCharacters?: number;
    onChange?: (value: string) => void;
    onCountryCodeChange?: (value: string, isOperable?: boolean) => void;
};

const PhoneElement: React.FC<PhoneElementProps> = ({
    countryCodeValue,
    value,
    operableCountryCodes,
    label,
    messages,
    name,
    countryCodeName,
    required,
    isValid,
    appendWhenValid,
    maxCharacters,
    placeholder,
    onChange,
    onCountryCodeChange,
    ...rest
}) => {
    const [isCountryCodeMenuOpen, setIsCountryCodeMenuOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState<string>(ascertainPhoneNumber(value));

    // We'll show the country code field if and only if we get a 'onCountryCodeChange' function as prop.
    const shouldUseCountryCodeField =
        typeof onCountryCodeChange === 'function' && !!countryCodeName;

    const countryCodesList = useMemo(() => {
        if (!operableCountryCodes || operableCountryCodes.length === 0) return [];

        return Array.isArray(operableCountryCodes) ? operableCountryCodes : [operableCountryCodes];
    }, [operableCountryCodes]);

    const countryCodeOptions = useMemo<CountryCodeOption[]>(() => {
        if (!shouldUseCountryCodeField) return [];

        return Object.entries(regionsToCountryCodes).map((entry) => {
            const [region, code] = entry;
            const country = regionsToCountries[region as keyof typeof regionsToCountries];

            // A country code is considered operable if we didn't specify operable country codes
            // or, if the country code is in the list of operable country codes.
            const isOperable =
                !operableCountryCodes ||
                (!!operableCountryCodes.length && operableCountryCodes.includes(region));

            return {
                value: region,
                label: `${code}:${country}:${region}`,
                code,
                country,
                region,
                isOperable,
            };
        });
    }, []);

    const getCountryCodeOption = useCallback(
        (countryCode: string) => countryCodeOptions.find((option) => option.value === countryCode),
        [countryCodeOptions],
    );

    const [countryCode, setCountryCode] = useState<SingleValue<CountryCodeOption>>(() => {
        const defaultOperableCountryCode = countryCodeValue || countryCodesList[0];

        return getCountryCodeOption(defaultOperableCountryCode) || countryCodeOptions[0] || null;
    });

    useEffect(() => setPhoneNumber(ascertainPhoneNumber(value)), [value]);

    useEffect(() => {
        if (countryCodeValue) {
            const option = getCountryCodeOption(countryCodeValue);

            if (option) setCountryCode(option);
        }
    }, [countryCodeValue]);

    const hasSelectedOperableCode = useMemo(() => {
        // Defaults to true if we're not using the country code field.
        if (!shouldUseCountryCodeField) return true;

        return !!countryCode?.value && countryCodesList.includes(countryCode.value);
    }, [countryCode, countryCodesList]);

    const handleCountryCodeChange = useCallback(
        (option: SingleValue<CountryCodeOption>) => {
            setCountryCode(option);

            if (onCountryCodeChange) onCountryCodeChange(option?.value ?? '', !!option?.isOperable);
        },
        [onCountryCodeChange],
    );

    const handlePhoneChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            const newValue = event.currentTarget.value;

            // We will only retain numbers on the input.
            const consolidatedValue = newValue.replace(/[^\d]/g, '');

            // If maxCharacters is defined, we will not allow the user to input more than what is defined.
            const conformsWithMaxCharacters =
                !maxCharacters || (maxCharacters && newValue.length <= maxCharacters);

            const isDeletingInput = newValue.length < phoneNumber.length;

            // We will only update  the value either if it confirms with max characters or user is deleting input.
            if (conformsWithMaxCharacters || isDeletingInput) {
                setPhoneNumber(consolidatedValue);

                if (onChange) onChange(consolidatedValue);
            }
        },
        [onChange, phoneNumber],
    );

    const isRequired = hasSelectedOperableCode && required;

    const shouldShowInoperableCountryCodeMessage =
        !hasSelectedOperableCode && !!messages?.inoperableCountryCode;

    const shouldShowErrorMessage = !shouldShowInoperableCountryCodeMessage && !!messages?.error;

    const shouldShowAppendWhenValid =
        (isValid && !!phoneNumber?.length) || !hasSelectedOperableCode;

    const selectStyles = useMemo(() => customStyles({ isInvalid: !isValid }), [isValid]);

    return (
        <StyledContainer>
            <StyledPhoneElement
                isError={!isValid}
                isSingleField={!shouldUseCountryCodeField}
                isMenuOpen={isCountryCodeMenuOpen}
            >
                {shouldUseCountryCodeField && (
                    <ReactSelect
                        name={countryCodeName}
                        styles={selectStyles}
                        options={countryCodeOptions}
                        formatOptionLabel={CustomOption}
                        isMulti={false}
                        value={countryCode}
                        onChange={handleCountryCodeChange}
                        // We will use a local state to allow use for styling.
                        menuIsOpen={isCountryCodeMenuOpen}
                        onMenuOpen={() => setIsCountryCodeMenuOpen(true)}
                        onMenuClose={() => setIsCountryCodeMenuOpen(false)}
                    />
                )}

                <StyledInput
                    {...rest}
                    type="tel"
                    name={name}
                    value={phoneNumber}
                    disabled={!hasSelectedOperableCode}
                    placeholder={placeholder ?? ' '}
                    onChange={handlePhoneChange}
                    data-testid="phone-input"
                />

                {label && (
                    <StyledLabel>
                        {label}
                        {isRequired && <span>*</span>}
                    </StyledLabel>
                )}

                {appendWhenValid && shouldShowAppendWhenValid && (
                    <StyledAdditionalContent>{appendWhenValid}</StyledAdditionalContent>
                )}
            </StyledPhoneElement>

            {shouldShowInoperableCountryCodeMessage && (
                <StyledHelpText>{messages.inoperableCountryCode}</StyledHelpText>
            )}

            {shouldShowErrorMessage && (
                <StyledInvalidMessage>{messages.error}</StyledInvalidMessage>
            )}
        </StyledContainer>
    );
};

export default PhoneElement;
